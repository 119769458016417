@import '../../default.scss';

$type_1_width: 500px;
$type_1_height: 300px;

$visible_duration: 15s;

.push-item {
    position: fixed;
    bottom: 60px;
    right: 80px;
    border-radius: 4px;

    box-shadow: 0px 0px 19px 5px rgba(2, 2, 2, 0.25);
    overflow: hidden;

    a {
        text-decoration: none;
        color: inherit;
    }

    .btn-link {

        position: relative;
        margin-top: auto;
        padding: 12px 22px;
        // background: transparent !important;
        // // color: rgb(1, 1, 212);
        border-radius: 4px;
        text-decoration: none;
        transition: 200ms ease;

        span {
            display: block;
            position: relative;
            z-index: 2;
            transition: 250ms ease-in;
            text-align: center;
        }

        &:hover {
            filter: brightness(1.1);
        }

        &:active {
            filter: brightness(1.2);

            div {
                color: inherit !important;
            }

            // &::after {
            //     opacity: 0;
            // }

            // background: inherit !important;
        }
    }

    .close-button {
        position: absolute;
        z-index: 20;
        top: 15px;
        right: 15px;
        width: 25px;
        height: 25px;
        border-radius: 100%;

        background: rgb(175, 174, 174);

        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: 250ms ease;

        i {
            color: #fff;
            font-size: 14px;
            transition: 250ms ease;
        }

        &:hover i {
            color: $clr_primary;
        }
    }
}

.push-item-type-1,
.push-item-type-3 {
    position: relative;
    width: $type_1_width;
    height: $type_1_height;

    .image-container {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }

        video {
            // width: 100%;
            height: 100%;
            margin-top: auto;
        }
    }

    .info-container {
        position: relative;
        z-index: 10;
        height: $type_1_height;
        padding: 15px 15px 15px 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

    }

    .text-container {
        position: absolute;
        z-index: 2;
        padding: 12px 20px;

        margin: 15px 40px 0 15px;
        border-radius: 8px;

        .with-bg {
            background: rgba(255, 255, 255, 0.67);
            backdrop-filter: blur(10px);
        }
    }
}

.push-item-type-2 {
    position: relative;
    width: $type_1_width;
    // height: $type_1_height;

    .info-container {
        position: relative;
        z-index: 10;
        padding: 15px 15px 15px 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

    }

    .text-container {
        padding: 12px 20px;
        margin: 15px 40px 0 15px;
        border-radius: 8px;

        .with-bg {
            background: rgba(255, 255, 255, 0.67);
            backdrop-filter: blur(10px);
        }
    }
}

.appear-from-right {
    animation: from-right forwards ease-in;
}

.appear-from-left {
    animation: from-left forwards ease-in;
}

.appear-from-right,
.appear-from-left {
    animation-duration: $visible_duration;

    &:hover {
        animation-play-state: paused;

        .line {
            animation-play-state: paused;
        }
    }
}

.line {
    position: absolute;
    z-index: 10;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 2px;
    background: $clr_primary;
    animation: line-width forwards $visible_duration linear;
}

.push-item-overlay {
    display: none;
}

@media screen and (max-width: 992px) {


    .push-item-overlay {
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.418);
    }

    .push-item-type-1,
    .push-item-type-2,
    .push-item-type-3 {
        width: 100%;

        .info-container {
            padding: 15px;
        }
    }

    .push-item {
        bottom: 0;
        left: 0;
        right: 0;
        border-radius: 12px 12px 0 0;
    }

    .appear-from-right,
    .appear-from-left {
        animation: from-bottom forwards ease-in !important;
        animation-duration: $visible_duration !important;
    }
}

@keyframes line-width {
    from {
        width: 100%;
    }

    to {
        width: 0%;
    }
}

@keyframes from-right {
    0% {
        opacity: 0;
        transform: translateX(100%);
    }

    1% {
        opacity: 1;
        transform: translateX(0);
    }

    99% {
        opacity: 1;
        transform: translateX(0);
    }

    100% {
        opacity: 0;
        transform: translateX(100%);
    }
}

@keyframes from-left {
    0% {
        opacity: 0;
        transform: translateX(100%);
    }

    1% {
        opacity: 1;
        transform: translateX(0);
    }

    95% {
        opacity: 1;
        transform: translateX(0);
    }

    100% {
        opacity: 0;
        transform: translateX(-1000%);
    }
}

@keyframes from-bottom {
    0% {
        opacity: 0;
        transform: translateY(100%);
    }

    1% {
        opacity: 1;
        transform: translateY(0);
    }

    95% {
        opacity: 1;
        transform: translateY(0);
    }

    100% {
        opacity: 0;
        transform: translateY(100%);
    }
}